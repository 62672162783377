import React from "react"
import styled from "@emotion/styled"

import Layout from "../layouts/index"
import SEO from "../components/seo"
import Background from "../components/background"
import { color } from "../utils"

const NotFoundWrapper = styled.article`
  margin: 3% 10%;
  padding: 2rem 4rem;
  color: ${color.primaryColor};
  font-size: 1.3rem;
  line-height: 2.5rem;
  background-color: ${color.backgroundTransparencyWhite};
  opacity: 0;

  animation: fade 1.5s ease-in-out forwards;
  @keyframes fade {
    to {
      opacity: 1;
    }
  }
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Background opacity="low" />
    <NotFoundWrapper>
      <h1>404: Not Found</h1>
      <p>
        You just hit a route that doesn&#39;t exist... Let's build it together!
      </p>
    </NotFoundWrapper>
  </Layout>
)

export default NotFoundPage
